import * as React from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPalette } from '@fortawesome/free-solid-svg-icons'

const ForeningarPage = () => {
  return (
    <Layout pageTitle="Föreningar">
      <div className='overflow-x-scroll'>
        <table className='ovve_table w-full shadow-md rounded-md'>
          <thead className='text-lg bg-fuchsia-900 text-white'>
            <tr>
              <td><FontAwesomeIcon icon={faPalette} /></td>
              <td>Färg</td>
              <td>Namn</td>
              <td>Program</td>
            </tr>
          </thead>
          <tbody >
            <tr>
              <td><div className='ovve_color_div bg-black'></div></td>
              <td>
                <details>
                  <summary>Jäävligt svart</summary>
                  <p>Fuckkking black</p>
                </details>
              </td>
              <td>ASP</td>
              <td>Affärssystem</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-gray-500'></div></td>
              <td>
                <details>
                  <summary>Betonggrå</summary>
                  <p>Concrete grey</p>
                </details>
              </td>
              <td>Bygg</td>
              <td>Byggingenjör</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-red-300'></div></td>
              <td>
                <details>
                  <summary>Svinrosa</summary>
                  <p>Piggy Pink</p>
                </details>
              </td>
              <td>EKP</td>
              <td>Ekonomi</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-blue-400'></div></td>
              <td>
                <details>
                  <summary>Exklusivmidnattsblå</summary>
                  <p>Exclusivemidnightblue</p>
                </details>
              </td>
              <td>HPP</td>
              <td>Hälsopedagogik</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-green-900'></div></td>
              <td>
                <details>
                  <summary>Systembolagetgrön</summary>
                  <p>The-place-where-you-buy-alcohol-green</p>
                </details>
              </td>
              <td>IMP</td>
              <td>Internationell marknadsföring</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-green-700'></div></td>
              <td>
                <details>
                  <summary>Jabba the hut-grön</summary>
                  <p>Jabba the hut green</p>
                </details>
              </td>
              <td>INFO</td>
              <td>Informatix</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-purple-800'></div></td>
              <td>
                <details>
                  <summary>Dödslila</summary>
                  <p>Deathpurple</p>
                </details>
              </td>
              <td>PIM</td>
              <td>Psykoligi, idrott och motion</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-green-300'></div></td>
              <td>
                <details>
                  <summary>Magiskt Skögröna</summary>
                  <p>Gorgeous Green</p>
                </details>
              </td>
              <td>LUT</td>
              <td>Lärarutbildning</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-red-600'></div></td>
              <td>
                <details>
                  <summary>Botbacksröd</summary>
                  <p>Beerdefenderred</p>
                </details>
              </td>
              <td>MASKIN</td>
              <td>Maskiningenjör</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-cyan-400'></div></td>
              <td>
                <details>
                  <summary>Blue balls blå</summary>
                  <p>Blue balls blue</p>
                </details>
              </td>
              <td>Basår</td>
              <td>Tekniskt Basår</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-red-900'></div></td>
              <td>
                <details>
                  <summary>Bag-in-box-röd</summary>
                  <p>Bag in box red</p>
                </details>
              </td>
              <td>OLAV / SOSH</td>
              <td>Arbetsvetenskap, Samhällsförändring & social hållbarhet, Socionom</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-blue-700'></div></td>
              <td>
                <details>
                  <summary>SJUKt blåblå</summary>
                  <p>Sick blueblue</p>
                </details>
              </td>
              <td>SJUK</td>
              <td>Sjuksköterska</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-orange-600'></div></td>
              <td>
                <details>
                  <summary>Guantanamoorange</summary>
                  <p>Guantanamoorange</p>
                </details>
              </td>
              <td>SOK</td>
              <td>Samhällsanalys och kommunikation</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-orange-200'></div></td>
              <td>
                <details>
                  <summary>Bländande Beige</summary>
                  <p>Bedazzling Beige</p>
                </details>
              </td>
              <td>TBI</td>
              <td>Teknisk biologi</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-yellow-400'></div></td>
              <td>
                <details>
                  <summary>U-gul</summary>
                  <p>U-yellow</p>
                </details>
              </td>
              <td>U</td>
              <td>Utvecklingsingenjörer</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-gray-200'></div></td>
              <td>
                <details>
                  <summary>A5 vit</summary>
                  <p>A5 White</p>
                </details>
              </td>
              <td>X-CHANGE</td>
              <td>Exchange students (utbytesstudenter)</td>
            </tr>

            <tr>
              <td><div className='ovve_color_div bg-yellow-200'></div></td>
              <td>
                <details>
                  <summary>0111kt gul</summary>
                  <p>0111kt yellow</p>
                </details>
              </td>
              <td>ÖDET</td>
              <td>Civil-data, Civil-intelligenta, Data/Elektro/Mekatronik -ingenjör </td>
            </tr>

          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default ForeningarPage